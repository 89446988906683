const { signUpClick } = require('./src/utils/tracker')

exports.onClientEntry = () => {
  document.addEventListener('click', event => {
    const target = event.target.closest('a')
    if (!target) return

    const href = target.getAttribute('href')

    if (href === 'https://app.atlas.co/login') {
      signUpClick(event)
      return
    }

    const isExternal =
      href.startsWith('http') && !href.includes(window.location.origin)

    if (isExternal) {
      event.preventDefault()

      window.open(href, '_blank', 'noopener,noreferrer')
      return
    }
  })
}
